import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'


Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
  },
  getters,
  plugins: [createPersistedState(
    { storage: window.sessionStorage }
  )],

})

export default store
