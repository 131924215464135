import {v2Query} from "@/api/common";

export default {
    formData: {},
    query() {
        return v2Query(this.queryForm);
    },
    resetQueryForm() { // 重置接口表单数据
        this.queryForm = {}
        return this;
    },
    returnQueryForm() { // 获取请求表单数据
        return this.queryForm;
    },

    queryFormProjectListSelect() {
        this.queryForm.project_list_select = {
            form: {},
        }
        return this;
    },
    queryFormCompanyAddressAdd(_form) {
        this.queryForm.company_address_add = {
            form: _form,
        }
        return this;
    },
    queryFormAddressInfo(_id) {
        this.queryForm.address_info = {
            form: {
                id: _id,
            },
        }
        return this;
    },
    queryFormAddressDel(_id) {
        this.queryForm.address_del = {
            form: {
                id: _id,
            },
        }
        return this;
    },
    queryFormAddressEdit(_form) {
        this.queryForm.address_edit = {
            form: _form,
        }
        return this;
    },
    queryFormShareGroup() {
        this.queryForm.share_group = {
            form: {},
        }
        return this;
    },

    queryFormShareAddress(_form) {
        this.queryForm.share_address = {
            form: _form,
        }
        return this;
    },


}
