<template>
  <div class="foot_wrap">
    <!--
    <div class="foot_a container">

      <dl>
        <dt>关于我们</dt>
        <dd><a>公司简介</a></dd>
      </dl>

      <dl>
        <dt>法律声明</dt>
        <dd><a>平台使用说明</a></dd>
        <dd><a>隐私政策</a></dd>
      </dl>

      <dl>
        <dt>帮助中心</dt>
        <dd><a>常见问题</a></dd>
        <dd><a>我要投诉</a></dd>
      </dl>

      <dl>
        <dt>联系我们</dt>
        <dd>公司邮箱：jianzhibei@126.com</dd>
        <dd>公司地址：北京市海淀区绿地中央广场38号院5号楼309室</dd>
      </dl>
      <div>
        <img src="../../assets/image/erweima.png">
      </div>

    </div>-->
    <div class="foot_b">
      <p>版权所有：学创联盟网络科技（北京）有限公司 法律声明： 京ICP备2020042085号</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
  mounted () { },
  methods: {},
  components: {},
};
</script>

<style lang='scss' scoped>
.foot_wrap {
  background-color: #191d25;
  color: #fff;
  .foot_a {
    padding-top: 45px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    dl {
      margin-right: 30px;
      dt {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 20px;
      }
      dd {
        line-height: 32px;
        font-size: 14px;
        color: #ccc;
        a {
          color: #ccc;
          cursor: pointer;
        }
      }
      div img {
        width: 120px;
        height: 100px;
      }
    }
  }
  .foot_b {
    background-color: #101216;
    line-height: 52px;
    font-size: 14px;
    text-align: center;
  }
}
</style>
