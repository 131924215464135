import { Main } from 'element-ui'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue'
import RouteReplaceSelf from "@/utils/routeReplaceSelf";

Vue.use(VueRouter)
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/',
        name: '/',
        redirect: '/home', //
        component: Index,
    },



    {
        path: '/index',
        name: 'index',
        redirect: '/home', //
        component: Index,
        children: [

            {
                path: '/home',
                name: 'home',
                component: () =>
                    import('@/views/home/index.vue')
            },
            {
                path: '/talentdb-index',
                name: 'talentdb-index',
                component: () =>
                    import('@/views/talentdb/index.vue'),

            },
            {
                path: '/reward-index',
                name: 'reward-index',
                component: () =>
                    import('@/views/reward/index.vue'),

            },


            {
                path: '/manage-company',
                name: 'manage-company',
                redirect: '/manage-company-index', //
                component: () =>
                    import('@/views/manage/manage-index.vue'),
                children: [
                    {
                        path: '/manage-company-index',
                        name: 'manage-company-index',
                        component: () =>
                            import('@/views/manage/company-index.vue')
                    },

                    {
                        path: '/manage-company-info',
                        name: 'manage-company-info',
                        component: () =>
                            import('@/views/manage/company-info.vue')
                    },
                    {
                        path: '/manage-company-editor',
                        name: 'manage-company-editor',
                        component: () =>
                        import ('@/views/manage/editor-company.vue')
                    },
                    {
                        path: '/manage-company-power',
                        name: 'manage-company-power',
                        component: () =>
                        import ('@/views/manage/power-manage.vue'),

                    }

                  ]

            },
            {
                path: '/project',
                name: 'project',
                redirect: '/project-audit',
                component: () =>
                    import ('@/views/project/project-index.vue'),
                    children:[
                        ////审核管理
                        {
                        path: '/project-audit',
                        name: 'project-audit',
                        redirect: '/project-audit-project', //
                        component: () =>
                            import ('@/views/project/audit/audit-index'),
                            children:[
                                {
                                    path: '/project-audit-job',
                                    name: 'project-audit-job',
                                    component: () =>
                                    import ('@/views/project/audit/audit-job.vue')
                                },
                                {
                                    path: '/project-audit-project',
                                    name: 'project-audit-project',
                                    component: () =>
                                    import ('@/views/project/audit/audit-project.vue')
                                },
                                {
                                    path: '/project-audit-zhongbao',
                                    name: 'project-audit-zhongbao',
                                    component: () =>
                                    import ('@/views/project/audit/audit-zhongbao.vue')
                                },
                                {
                                    path: '/project-audit-zhuguan',
                                    name: 'project-audit-zhuguan',
                                    component: () =>
                                    import ('@/views/project/audit/audit-zhuguan.vue')
                                },

                             ]
                        },
                        //审核设置
                        {
                            path: '/audit-settings',
                            name: 'audit-settings',
                            component: () =>
                            import ('@/views/project/project/audit-settings.vue'),

                            children:[

                            ]
                        },
                        //项目管理
                        {
                            path: '/project-manage',
                            name: 'project-manage',
                            component: () =>
                            import ('@/views/project/project/project-manage.vue'),

                            children:[

                            ]
                        },
                        //项目管理-职位列表  众包列表
                        {
                            path: '/project-manage-detail',
                            name: 'project-manage-detail',
                            redirect: '/project-manage-job', //
                            component: () =>
                            import ('@/views/project/project/project-manage-detail.vue'),
                            children:[
                                {
                                    path: '/project-manage-job',
                                    name: 'project-manage-job',
                                    component: () =>
                                    import ('@/views/project/project/project-manage-job.vue'),
                                },
                                {
                                    path: '/project-manage-zhongbao',
                                    name: 'project-manage-zhongbao',
                                    component: () =>
                                    import ('@/views/project/project/project-manage-zhongbao.vue'),
                                },
                            ]
                        },





                ]
            },

            {
                path: '/company-address',
                name: 'company-address',
                component: () =>
                    import('@/views/companyAddress/address-list.vue'),
            },

            {
                path: '/address',
                name: 'address',
                redirect: '/address-address-gstxl',
                component: () =>
                    import('@/views/address/address-index.vue'),
                children: [
                    {
                        path: '/address-address-gstxl',
                        name: 'address-address-gstxl',
                        component: () =>
                            import('@/views/address/address-gstxl.vue')
                    },
                    {
                        path: '/address-address-zg',
                        name: 'address-address-zg',
                        component: () =>
                            import('@/views/address/address-zg.vue')
                    },
                    {
                        path: '/address-address-du',
                        name: 'address-address-du',
                        component: () =>
                            import('@/views/address/address-du.vue')
                    },
                    {
                        path: '/address-address-kf',
                        name: 'address-address-kf',
                        component: () =>
                            import('@/views/address/address-kf.vue')
                    },


                ]
            },


            {
                path: '/pay',
                name: 'pay',
                component: () =>
                    import('@/views/pay/pay-index.vue')
            },

            {
                path: '/application',
                name: 'application',
                component: RouteReplaceSelf(() => import("@/views/application/application-index.vue")),

                children: [
                    {
                        path: '/application-report-list',
                        name: 'application-report-list',
                        component: () =>
                            import('@/views/application/report/list.vue')
                    },
                    {
                        path: '/application-clock-report-day',
                        name: 'application-clock-report-day',
                        component: () =>
                        import('@/views/application/clock/report_day.vue')
                    },
                    {
                        path: '/application-clock-report-month',
                        name: 'application-clock-report-month',
                        component: () =>
                        import('@/views/application/clock/report_month.vue')
                    },
                    {
                        path: '/application-approval-list',
                        name: 'application-approval-list',
                        component: () =>
                        import('@/views/application/approval/list.vue')
                    },{
                        path: '/application-notice-list',
                        name: 'application-notice-list',
                        component: () =>
                        import('@/views/application/notice/list.vue')
                    },



                ]
            },

        ]
    },


    // 登录
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('@/views/user/login.vue')
    },



]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    //localStorage.clear();
    //to到哪儿  from从哪儿离开  next跳转 为空就是放行
    if (to.path === '/login') {
        //如果跳转为登录，就放行
        next();
    } else {
        //取出localStorage判断
        let token = localStorage.getItem('token');
        if (token == null || token === '') {
            // console.log('请先登录')
             next({ path: '/login' });

            next();
        } else {
            next();
        }
    }
});

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);

});

export default router
