export const common = {

    goUserHome(user_app_id){
      let routeData = this.$router.resolve({ name: "member-index", query: { id: user_app_id }});
      window.open(routeData.href, '_blank');
    },
    
    goTo (name,query) {
      let routeData = this.$router.resolve({ name: name, query: query});
      window.open(routeData.href, '_blank');
    },

   /* goTo (name,query) {
      this.$router.push({ name:name, query: query });
      //this.$router.push({ name: "member-index", query: { id: this.obj.user_app_id } });
    },*/


    openUrl(url){
        window.open(url)
    },
//user_type,
    goChat(send_uid,receive_uid,link=2){
      this.ws.initWebSocket(this.wsUrl+"?send_user_id="+send_uid+"&receive_user_id="+receive_uid+"&link="+link);
      this.$router.push({ name: "director-chat-info" });

     // this.$router.push({ name: "personal-chat-info" });

    },
   
  }