import urlConfig from './config'
import axios from 'axios'
import router from '../router/index'
import { Message } from 'element-ui';
axios.defaults.withCredentials = true;

//基准url
const { baseURL } = urlConfig.prod
const service = axios.create({
    baseURL: '/apiServe',
    // baseURL,
    timeout: 5000, // 请求超时时间
    withCredentials: true // 允许携带cookie
        // headers: { "content-type": "application/x-www-form-urlencoded" }
})

//请求拦截器
service.interceptors.request.use((config) => {

    if (config.push === '/') {

    } else {
        if (localStorage.getItem('token')) {
            //在请求头加入token，名字要和后端接收请求头的token名字一样
            config.headers.token = localStorage.getItem('token');
        }
    }
    return config
}, (error) => {
    return Promise.reject(error)
})



//响应式拦截器
service.interceptors.response.use(response => {
    const code = response.data.code || 200;
    if (code == 200) {
        return response.data;
    }
    if (code == 403) {
        //清除token
        localStorage.removeItem('token');

        //跳转
        router.push({ name: 'login' });
    }
    return response.data;

}, err => {
    console.log( 'error',err);

    if (err && err.response) {
        err.message =  err.response.data&&err.response.data.message?err.response.data.message:"服务器错误"
        /*switch (err.response.status) {
            case 400: err.message = '请求错误(400)'; break;
            case 401: err.message = '未授权，请重新登录(401)'; break;
            case 403: err.message = '拒绝访问(403)'; break;
            case 404: err.message = '请求出错(404)'; break;
            case 408: err.message = '请求超时(408)'; break;
            case 500: err.message = '服务器错误(500)'; break;
            case 501: err.message = '服务未实现(501)'; break;
            case 502: err.message = '网络错误(502)'; break;
            case 503: err.message = '服务不可用(503)'; break;
            case 504: err.message = '网络超时(504)'; break;
            case 505: err.message = 'HTTP版本不受支持(505)'; break;
            default: err.message = `连接出错(${err.response.status})!`;
        }*/
    } else {
        err.message = '连接服务器失败!'
    }
  Message.error( {message: err.message } )



    return Promise.reject(err)
})
export default service
