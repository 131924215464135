import http from '@/api/request'
import NProgress from 'nprogress'
import { MessageBox } from 'element-ui'


export const file = {

    download(url, params, fileName) {
      NProgress.start()
      return http.get(url, {
        params: params
      }).then((res) => {
        if (res && res.status === 200) {

          const blob = new Blob([res.data], { type: res.data.type })
          this.exportLoading = false
  
          // for IE
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName)
          } else {
            // for Non-IE (chrome, firefox etc.)
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)
          }
          NProgress.done()
        }
      }).catch((r) => {

       /*const blob = new Blob([r], { type: r.type })
        blob.text().then(data =>{
          console.log(data)
            // data：指成功读取到的内容
        }).catch(err =>{
            //err： 指读取失败后返回的错误内容
        })*/
        console.error(r)
        NProgress.done()
        MessageBox({
          message: '下载失败',
          type: 'error',
          duration: 5 * 1000
        })
      })
    },


    openUrl(url){
        window.open(url)
    }
   
  }