import { userInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    userInfo:null,//
   // token: getToken(),
   // user_id:''
    user_type:1,
    token:getToken(),
    isLogin:false,
    
  },

  mutations: {

    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERINFO: (state, info) => {
      state.userInfo = info
    },

    SET_LOGIN: (state, isLogin) => {
      state.isLogin = isLogin
    },

    SET_USERTYPE: (state, user_type) => {
      state.user_type = user_type
    },

  },


  actions: {
    // 登录
    Login({ commit }, userInfo) {

      setToken(userInfo.token)

      commit('SET_USERTYPE', userInfo.user_type)
      commit('SET_USERINFO', userInfo)
      commit('SET_TOKEN', userInfo.token)
      commit('SET_LOGIN', true)
    },

     // 登录
     changeUserType({ commit }, user_type) {

      
      commit('SET_USERTYPE', user_type)

    },

  

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        userInfo({ }).then((response) => {
          console.log(response);
          const user = response.data;
          if(user){
            commit('SET_USERINFO', user)
            commit('SET_USERINFO', user)
            commit('SET_TOKEN', user.token)
            commit('SET_LOGIN', true)
          }
          resolve(response);
        }).catch(error => {
          reject(error)
        });
      })
    },
    
    // 退出系统
    LogOut({ commit }) {

      commit('SET_USERINFO', '')
      commit('SET_TOKEN', '')
      commit('SET_LOGIN', false)
      removeToken()

    },
  }
}

export default user
