
export default{
    websocket:null,
    initWebSocket(url) {
        console.log("initWebSocket");
        if(this.websocket){
            this.websocket.close();
        }
        //初始化weosocket
         this.websocket = new WebSocket(url);
         this.websocket.onopen = this.wsOnOpen;
         this.websocket.onerror = this.wsOnError;
         this.websocket.onmessage = this.wsOnMessage;
         this.websocket.onclose = this.wsOnClose;
         
        //return websocket;
    },

    setWebSocket(newws){
        this.websocket=newws;
    },

    wsOnOpen(){
        console.log("WebSocket连接成功");
        /*let data = {
            type: "user_init",
            userid: 'xxx'
        }
        this.websocket.send(
            // 发送数据
            JSON.stringify(data)
        );*/
    },
    wsOnError(e) {
        //错误
        console.log("wsOnError",e);
        // this.initWebSocket()
        console.log("WebSocket连接发生错误");
    },
    wsOnMessage(e) {
        //数据接收  
    // var that = this;
    // var data = JSON.parse(e.data);
        //this.$store.commit('SET_ws', data)
        console.log("接收数据");
        //操作处理...
    },

    websocketsend(agentData) {
        console.log("websocketsend");
        //数据发送
       // websocket.send(agentData);
       if(this.websocket){
        this.ws.websocket.send(agentData);
        }
    },

    wsOnClose(e) {
        //关闭
        console.log("webSocket 关闭");
       // console.log(JSON.stringify(e));
    },
    close(){
        if(this.websocket){
            this.websocket.close();
        }
    }


}