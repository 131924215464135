export default function routeReplaceSelf(component) {
    return {
      name: 'routerReplaceSelf',
      computed: {
        showChild() {
          const deepestMatchedRoute = this.$route.matched[this.$route.matched.length - 1];
          return deepestMatchedRoute.instances.default !== this;
        },
        cache() {
          return this.$route.meta.cache
        },
      },
      render(h) {
        const child = this.showChild ? h('router-view') : h(component);
        if (this.cache) {
          return h('keep-alive', [child])
        } else {
          return child
        }
      },
    };
  }