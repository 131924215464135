<template>
  <div class="header_wrap">
    <div class="container">
      <div class="header_left">
        <img class="logo" src="@/assets/image/logo.png" @click="goHome()" />
      </div>

      <nav>
        <ul>
          <li v-for="(item, index) in navs" :key="index" @click="changeActive(item)" :class="{ 'nav-active': item.active }">
            <router-link :to="item.path">
              <!--<img :src="item.active? item.iconActive: item.icon" alt="" />-->
              <span>{{ item.title }}</span>
              <aside v-if="item.num">{{ item.num }}</aside>
            </router-link>
          </li>
        </ul>
      </nav>


      <div class="header_right">
        <p> {{ company_name }} </p>

        <el-popover placement="bottom" width="150" trigger="hover">
          <!--
          <div style="display: flex; align-items: center; padding: 10px 0" @click="personal">
            <img src="../../assets/image/个人中心.png" alt="" />
            <span>个人中心</span>
          </div>-->



          <div style="display: flex; align-items: center; padding: 10px 0" @click="doOut">
            <img src="../../assets/image/login_out.png" alt="" />
            <span>退出登录</span>
          </div>

          <img class="avatars" slot="reference" :src="avatar" />
        </el-popover>
      </div>

    </div>

  </div>
</template>

<script>
// 引入接口

import default_avatar from '@/assets/image/avatar.png'

import { mapGetters } from 'vuex'
import { logout } from "@/api/login/index";


export default {
  data () {
    return {
      avatar: default_avatar,
      company_name: localStorage.getItem("company_name"),
      navs: [
        {
          title: "首页",
          name: 'home',
          path: '/home',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },

        {
          title: "公司管理",
          name: 'manage-company',
          path: '/manage-company',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },


        {
          title: "企业项目",
          name: 'project',
          path: '/project',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },

        {
          title: "通讯录",
          name: 'address',
          path: '/address',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },

        {
          title: "人才库",
          name: 'talentdb-index',
          path: '/talentdb-index',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },


        {
          title: "应用管理",
          name: 'application',
          path: '/application',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },

        {
          title: "支付系统",
          name: 'pay',
          path: '/pay',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "奖惩",
          name: 'reward-index',
          path: '/reward-index',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
        {
          title: "地址",
          name: 'company-address',
          path: '/company-address',
          icon: '',
          iconActive: '',
          num: 0,
          active: false
        },
      ],

      options: [],
      msgNum: 0
    };
  },


  computed: {
    ...mapGetters([
      'userInfo',
      'isLogin',
    ]),
  },



  created () {
    this.avatar = localStorage.getItem("avatar");

    // this.changeActive();
    this.initNav();

  },
  mounted () {
  },


  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.initNav();
      },
      // 深度观察监听
      deep: true
    },
    "$store.state.user.user_type": {
      //深度监听设置为
      deep: true,//
      handler: function (newVal, oldVal) {
        //修改数据时，能看到输出结果
        console.log(oldVal, newVal);
        this.initNav();

      }
    }
  },


  methods: {

    initNav () {


      var has=false;
      this.navs.forEach((item) => {
        if(item.path == this.$route.path){
          item.active=true;
          has=true;
          //continue;
        }else {
           item.active=false;

        }
       // item.active = (item.path == this.$route.path || this.$route.path.includes(item.name) ? true : false);
      });

      if(has==false){
        this.navs.forEach((item) => {
         item.active = (item.path == this.$route.path || this.$route.path.includes(item.name) ? true : false);

      });
      }

    },


    changeActive (nav) {
      var that = this;

      this.navs.forEach((item) => {
        if (item.path == nav.path) {
          console.log(item.path)
          item.active = true;
        } else {
          item.active = false;
        }

      });
    },


    goHome () {
      this.$router.push({ name: "index" });
    },
    homes () {
      this.$router.push({ name: "Home" });
    },

    doOut () {
      let data = {};
      logout(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("退出成功");
          //清除token
          localStorage.removeItem("token");
          localStorage.clear();
          //跳转
          this.$router.push({ name: "login" });
        }
      });
    },



  },
  components: {},
};
</script>

<style lang='scss' scoped>
.header_wrap {
  background-color: #4c71db;
  height: 64px;
  .container {
    height: 64px;
    display: flex;
    justify-content: space-between;
    .header_left {
      display: flex;
      align-items: center;
      .logo {
        width: 101px;
      }
    }
    nav ul {
      min-width: 650px;
      display: flex;
      a {
        position: relative;
        padding: 0 15px;
        line-height: 64px;
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 16px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .msg_box {
      margin-left: 20px;
      padding: 0 20px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 18px;
      }
    }
    .header_right {
      display: flex;
      align-items: center;
      font-size: 16px;
      p {
        color: #ffffff;
      }
      img {
        margin-left: 24px;
        width: 34px;
        height: 34px;
        border-radius: 17px;
      }
    }
    aside {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2px;
      right: 0;
      color: #fff;
      background-color: #ff4e4e;
      border-radius: 10px;
      line-height: 20px;
      font-size: 12px;
      text-align: center;
    }
  }
}

/deep/ .el-menu-item.is-active {
  color: white !important;
  background-color: #042683 !important;
}
/deep/ .el-cascader .el-input {
  width: 90px;
  border: none !important;
  margin-right: 20px;
}
/deep/ .el-cascader .el-input .el-input__inner {
  color: white;
  border: none;
  background-color: transparent;
}
/deep/ .el-popover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-active {
  background: #03238c;
}
</style>
