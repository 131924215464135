import request from '@/api/request'

//PC企业端-注册接口
export function register(data) {
    return request({
        url: '/company/register',
        method: 'POST',
        data
    })
}

//PC企业端-登录接口
export function login(data) {
    return request({
        url: '/company/tologin',
        method: 'POST',
        data
    })
}

export function companyList(data) {
    return request({
        url: '/company/company-list',
        method: 'POST',
        data
    })
}


export function forgot(data) {
    return request({
        url: '/company/forgot',
        method: 'POST',
        data
    })
}


export function logout(data) {
    return request({
        url: '/company/logout',
        method: 'POST',
        data
    })
}



//获取手机验证码接口
export function mobilesms(data) {
    return request({
        url: '/api/sms',
        method: 'POST',
        data
    })
}

//获取我的账户信息接口
export function userlist(data) {
    return request({
        url: '/web/user/index',
        method: 'GET',
        params:data
    })
}

